import note from "../../assets/images/note.svg";

function MusicCard(props) {
  return (
    <>
      {props.show && (
        <div className="music-card">
          <a
            href={`https://music.danielospid.com/${props.path}`}
            target="_blank"
            rel="noopener noreferrer"
            className="music-card-link"
          >
            <div className="music-card-cover">
              {props.covers ? (
                <img
                  src={props.covers[0]}
                  alt={"Portada de " + props.title}
                  className="music-cover"
                  loading="lazy"
                />
              ) : (
                <div
                  className="music-cover"
                  style={{ backgroundColor: `#${props.color}80` }}
                >
                  • • •
                </div>
              )}
              {props.songs && (
                <div
                  className="music-card-songs"
                  style={{ backgroundColor: `#${props.color}` }}
                >
                  <span className="music-card-songs-icon">
                    <img src={note} alt="Note" />
                  </span>
                  <span className="music-card-songs-text">
                    {props.songs.length > 1 ? (
                      <>{props.songs.length + " Canciones"}</>
                    ) : (
                      <>{props.songs.length + " Canción"}</>
                    )}
                  </span>
                </div>
              )}
              <div className="music-card-screen"></div>
            </div>
            <div className="music-card-caption">
              <span className="music-card-title">{props.title}</span>
              <span className="music-card-type">
                Daniel Ospid - {props.type}
              </span>
            </div>
          </a>
        </div>
      )}
    </>
  );
}

export default MusicCard;
