import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Main from "./Main";
import { getAuth, signOut } from "firebase/auth";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function Layout() {
  const auth = getAuth();
  const authUser = useAuth(db);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("Cierre de sesión exitoso");
    } catch (error) {
      console.log("Error al cerrar la sesión", error);
    }
  };

  return (
    <div className="app home">
      <Main />
      {authUser && (
        <>
          <button
            onClick={() => handleSignOut()}
            title="Cerrar Sesión"
            className="sign-out"
          >
            <FontAwesomeIcon icon={faSignOut} size="xl" />
          </button>
        </>
      )}
    </div>
  );
}
export default Layout;
