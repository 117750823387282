import { db } from "../../firebase/config";
import { useParams } from "react-router-dom";
import { useMusic, usePlaylists } from "../../firebase/fbQueries";
import MusicPage from "./MusicPage";
import NoFound from "./NoFound";
import PlaylistPage from "./PlaylistPage";

function Music() {
  const { path } = useParams();
  const music = useMusic(db);
  const playlist = usePlaylists(db);
  const musicSelected = music.find((music) => music.path === path);
  const playlistSelected = playlist.find((playlist) => playlist.path === path);

  return (
    <main className="main">
      <section className="section">
        <div className="container">
          {musicSelected ? (
            <MusicPage
              musicSelected={musicSelected}
              path={musicSelected.path}
              tags={musicSelected.tags}
              color={musicSelected.color}
              cover={musicSelected.cover}
              covers={musicSelected.covers}
              cover_alt={musicSelected.cover_alt}
              title={musicSelected.title}
              artists={musicSelected.artists}
              date={musicSelected.date}
              delete={musicSelected.delete}
              removed={musicSelected.removed}
              pre_save={musicSelected.pre_save}
              pre_add={musicSelected.pre_add}
              spotify={musicSelected.spotify}
              apple_music={musicSelected.apple_music}
              tidal={musicSelected.tidal}
              amazon_music={musicSelected.amazon_music}
              youtube_music={musicSelected.youtube_music}
              deezer={musicSelected.deezer}
              soundcloud={musicSelected.soundcloud}
              audiomack={musicSelected.audiomack}
              youtube={musicSelected.youtube}
              store={musicSelected.store}
              preview={musicSelected.preview}
              show_music={musicSelected.show_music}
            />
          ) : playlistSelected ? (
            <PlaylistPage
              path={playlistSelected.path}
              tags={playlistSelected.tags}
              cover={playlistSelected.cover}
              cover_alt={playlistSelected.cover_alt}
              title={playlistSelected.title}
              spotify={playlistSelected.spotify}
              apple_music={playlistSelected.apple_music}
              tidal={playlistSelected.tidal}
              amazon_music={playlistSelected.amazon_music}
              youtube_music={playlistSelected.youtube_music}
              deezer={playlistSelected.deezer}
              soundcloud={playlistSelected.soundcloud}
              audiomack={playlistSelected.audiomack}
              youtube={playlistSelected.youtube}
            />
          ) : (
            <NoFound />
          )}
        </div>
      </section>
    </main>
  );
}
export default Music;
