import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

// ---- ---- music ---- ---- //
export function useMusic(db) {
  const [music, setMusic] = useState([]);

  useEffect(() => {
    const musicRef = collection(db, "music");

    const unsubscribe = onSnapshot(musicRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setMusic(data);
    });

    return () => unsubscribe();
  }, [db]);

  return music;
}

// ---- ---- playlists ---- ---- //
export function usePlaylists(db) {
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    const playlistsRef = collection(db, "playlists");

    const unsubscribe = onSnapshot(playlistsRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setPlaylists(data);
    });

    return () => unsubscribe();
  }, [db]);

  return playlists;
}

// ---- ---- authentication ---- ---- //
export function useAuth(db) {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, [db]);

  return authUser;
}